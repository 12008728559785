import { formatDate } from "src/Utils/DateUtils";
import { User } from "../../PartnerCommunication/BalanceConfirmationBeta/MoveTaskToReconModal";

export enum REPORT_TYPES {
  Status = "status",
  Funnel = "funnel",
}

export interface MISFilters {
  category?: string[];
  relationship?: string;
  taskLabel?: string[];
  approver?: User[];
  period?: Date;
  activityPeriod?: { from: Date; to: Date };
  taskBucket?: string[];
}
export enum REPORT_VIEWS {
  TaskLabel = "Task Label",
  Category = "Category",
  Relationship = "Relationship",
  Company = "Company",
  Branch = "Branch",
  Approver = "Approver",
  ActivityPeriod = "Activity Period",
  Maker = "Maker",
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  HalfYearly = "Half Yearly",
  Yearly = "Yearly",
}
export interface ListAllReportResponse {
  categories: string[];
  labels: string[];
  stakeholders: string[];
  users: User[];
  reports: MISReportDetails[];
}
export enum FUNNEL_METRIC_TYPES {
  Volume = "volume",
  Value = "value",
}
export interface MISReportDetails {
  id: number;
  reportType: REPORT_TYPES;
  views: any[];
  filters?: MISFilters;
  hiddenColumns: string[];
  reportData: any[];
  columnsOrder: string[];
  metric?: any[];
  reportName: string;
}

export enum REPORT_FILTERS {
  Category = "Category",
  Relationship = "Relationship",
  TaskLabel = "Task Label",
  Approver = "Approver",
  Stakeholder = "Stakeholder",
  Period = "Period",
  ActivityPeriod = "Activity Period",
  TaskBucket = "Task Bucket",
}

export const ALL_RELATIONSHIPS = ["Vendor", "Customer"];

export const FUNNEL_REPORT_COLUMNS = {
  numberOfPartners: "Total Partners",
  partnersContacted: "Partners Contacted",
  partnersResponded: "Partners Responded",
  partnersConfirmedBalance: "Partner Confirmed Balance",
  partnersSharedLedger: "Partner Shared Ledger",
  reconDone: "Recon Done",
  CBDiff: "CB Difference",
  ownBalance: "Own Balance",
  partnerBalance: "Partner Balance",
  invoiceNotBookedByPartner: "Invoice Not Booked by Partner",
  invoiceNotBookedByOwn: "Invoice Not Booked by Own",
  invoiceMisMatch: "Invoice Mismatch",
  paymentNotBookedByPartner: "Payment Not Booked by Partner",
  paymentNotBookedByOwn: "Payment Not Booked by Own",
  paymentMisMatch: "Payment Mismatch",
  dncnNotBookedByPartner: "DN/CN Not Booked by Partner",
  dncnNotBookedByOwn: "DN/CN Not Booked by Own",
  dncnMisMatch: "DN/CN Mismatch",
  tdsNotBookedByPartner: "TDS Not Booked by Partner",
  tdsNotBookedByOwn: "TDS Not Booked by Own",
  invoiceReconciled: "Invoice Reconciled",
  paymentReconciled: "Payment Reconciled",
};

export const VIEW_COLUMNS = [
  "relationship",
  "category",
  "taskLabel",
  "approver",
  "activityPeriod",
  "metric",
  "branch",
  "company",
];
export const FUNNEL_REPORT_ALL_COLUMNS = [
  "numberOfPartners",
  "partnersContacted",
  "partnersResponded",
  "partnersConfirmedBalance",
  "partnersSharedLedger",
  "reconDone",
  "CBDiff",
  "ownBalance",
  "partnerBalance",
  "invoiceNotBookedByPartner",
  "invoiceNotBookedByOwn",
  "invoiceMisMatch",
  "paymentNotBookedByPartner",
  "paymentNotBookedByOwn",
  "paymentMisMatch",
  "dncnNotBookedByPartner",
  "dncnNotBookedByOwn",
  "dncnMisMatch",
  "tdsNotBookedByPartner",
  "tdsNotBookedByOwn",
];

export const REPORT_VIEWS_MAPPING = {
  taskLabel: "Task Label",
  category: "Category",
  branch: "Branch",
  company: "Company",
  approver: "Approver",
  relationship: "Relationship",
  daily: "Daily",
  weekly: "Weekly",
  monthly: "Monthly",
  yearly: "Yearly",
  activityPeriod: "Activity Period",
  taskBucket: "Task Bucket",
};

export const renderValueForMISFilters = (key: string, value: any) => {
  if (key === "approver") {
    return value.map((user) => user.name).join(", ");
  }
  if (Array.isArray(value)) return value.join(", ");
  if (typeof value === "string") return value;
  if (key === "period") return formatDate(value);
  if (key === "activityPeriod") return `${formatDate(value.from)} - ${formatDate(value.to)}`;
};
