import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, Chip, IconButton, Typography } from "@mui/material";
import React from "react";
import { renderValueForMISFilters, REPORT_VIEWS_MAPPING } from "./Interfaces";

export default function AppliedFiltersBar({ appliedFilters, setIsFilterApplied, setAppliedFilters }) {
  const entries = Object.entries(appliedFilters);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        marginTop: 2,
        gap: 1,
        bgcolor: "#fff",
        borderRadius: 1,
        border: "1px solid rgba(0, 0, 0, 0.12)",
        overflow: "scroll",
      }}
    >
      <IconButton>
        <FilterListIcon />
      </IconButton>
      {entries.map(([key, value]) => (
        <Chip
          key={key}
          label={
            <Box component="span">
              <Typography component="span" sx={{ fontWeight: "bold" }}>
                {REPORT_VIEWS_MAPPING[key]}:
              </Typography>
              <Typography component="span" sx={{ fontWeight: "normal", mx: 0.5 }}>
                {renderValueForMISFilters(key, value)}
              </Typography>
            </Box>
          }
          onDelete={() => {
            const newFilters = { ...appliedFilters };
            delete newFilters[key];
            setAppliedFilters(newFilters);
            if (Object.keys(newFilters).length === 0) {
              setIsFilterApplied(false);
            }
          }}
          deleteIcon={<CloseIcon />}
          sx={{
            "& .MuiChip-label": { fontSize: 13, padding: 0.5 },
          }}
        />
      ))}
      <IconButton
        onClick={() => {
          setIsFilterApplied(false);
          setAppliedFilters({});
        }}
        sx={{
          background: "#fff",
          position: "absolute",
          right: 2,
          "&:hover, &.Mui-focusVisible": { background: "#fff" },
        }}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
}
