import { Add, Delete, Edit } from "@mui/icons-material";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControlLabel,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Android12Switch } from "src/Components/Common/Loader";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import { ListUserEmailTemplateResponse, UseCaseType } from "src/entity/recon-entity/ReconInterfaces";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { uiLoggerNamesPartnerCommunication } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { DialogSection } from "../BalanceConfirmationBeta/CommunicationTypes/CommsTypesCommon";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID, RequireAtLeastOne } from "../CommonLegacy/CommonComponents";
import EmailTemplateDeleteWarningModal from "../CommonLegacy/EmailTemplateDeleteWarningModal";
import { getShortName } from "../CommonLegacy/MailThreadDialog";
import { PartnerCommsContext } from "../Context/PartnerCommunicationContext";
import Editor from "../Editor/Editor";
import { UpdateUserEmailSettingDialog } from "./CommonDialogs";
import CreateEmailTemplate from "./CreateEmailTemplate";
import NewEditor from "./NewEditor";

export interface EmailEditorStateContentInput {
  emailSubject: string;
  editorContent: string;
  templateError: boolean;
}

export interface EmailEditorStateTemplateInput {
  emailTemplateId: number;
  templateError: boolean;
}

export interface EmailEditorState extends EmailEditorStateContentInput, EmailEditorStateTemplateInput {}

export type EmailEditorsWithTemplateInputProps = RequireAtLeastOne<
  EmailEditorState,
  "emailTemplateId" | "editorContent"
>;

interface EmailEditorProps {
  inputEditorState?: EmailEditorStateContentInput | EmailEditorStateTemplateInput;

  returnEditorState?: (emailSubject: string, editorContent: string, emailTemplateId: number, files: File[]) => void;

  deleteUserEmailTemplate?: (templateID: number) => void;
  sectionsCustomizations?: { subject?: boolean; editor?: boolean; uploadArea?: boolean; signature?: boolean };
  otherProps: {
    listUserEmailTemplates: (createTemplateName: string) => void;
    templateType: UseCaseType;
    companyId: string;
    branchCode: string;
  };
}

/**
 *
 * @param {EmailEditorProps} props
 * @description inputEditorState: either of (`editorContent & emailSubject`) or `emailTemplateId` has to be passed
 * @description
 * if `emailTemplateId` is passed, then the email subject and editor content will be set from the email template
 * @description returnEditorState: emailSubject, editorContent, emailTemplateId
 *
 * @return {*}
 */
const EmailEditorWithTemplate: React.FC<EmailEditorProps> = (props: EmailEditorProps) => {
  const [emailSubject, setEmailSubject] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [templateId, setTemplateId] = useState<number>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [copyAlert, setCopyAlert] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const [openEmailTemplate, setOpenEmailTemplate] = useState(false);
  const [openUpdateEmailSettingDlg, setOpenUpdateEmailSettingDlg] = useState(false);
  // const [userEmailSetting, setUserEmailSetting] = useState<ListUserEmailSettingRes["userEmailSetting"]>({} as any);

  const [oldEditorMode, setOldEditorMode] = useState(false);

  const { actor } = useContext(userContext);
  const {
    emailTemplates,
    setEmailTemplates,
    setIsOpenDeleteModal,
    handleCloseDeleteModal,
    dynamicContentOptions,
    userEmailSetting,
  } = useContext(PartnerCommsContext);

  useEffect(() => {
    const inputEditorState = props.inputEditorState as EmailEditorStateTemplateInput;

    if (inputEditorState?.emailTemplateId) {
      const filteredSelectedTemplate = emailTemplates.filter((item) => {
        return item.templateId === inputEditorState?.emailTemplateId;
      });

      setTemplateId(inputEditorState?.emailTemplateId);

      if (filteredSelectedTemplate.length === 0) {
        setEmailSubject("");
        setEditorContent("");
        return;
      }

      console.log("filteredSelectedTemplate", filteredSelectedTemplate);

      const { emailBody, emailSubject } = filteredSelectedTemplate[0];

      setEmailSubject(emailSubject);
      setEditorContent(emailBody);
    }
  }, [(props.inputEditorState as EmailEditorStateTemplateInput)?.emailTemplateId]);

  useEffect(() => {
    const inputEditorState = props.inputEditorState as EmailEditorStateContentInput;

    if ((props.inputEditorState as EmailEditorState)?.emailTemplateId) {
      return;
    }

    if (inputEditorState?.emailSubject && inputEditorState?.editorContent) {
      setEmailSubject(inputEditorState.emailSubject);
      setEditorContent(inputEditorState.editorContent);
    }
  }, [props.inputEditorState]);

  useEffect(() => {
    if (props.returnEditorState) {
      props.returnEditorState(emailSubject, editorContent, templateId, files);
    }
  }, [props.returnEditorState, emailSubject, editorContent, templateId, files]);

  // User Email Templates
  const listUserEmailTemplateForBcBeta = (_createTemplateName: string) => {
    useFetch<ListUserEmailTemplateResponse>(API_ENDPOINTS.LIST_USER_EMAIL_TEMPLATE_PARTNER_COMMUNICATION.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_USER_EMAIL_TEMPLATE_PARTNER_COMMUNICATION.failureMessage,
      config: {
        params: {
          templateType: props.otherProps?.templateType,
          companyId: props.otherProps?.companyId || DEFAULT_COMPANY_ID,
          branchCode: props.otherProps?.branchCode || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (response) => {
        const { emailTemplates } = response.data;
        setEmailTemplates(emailTemplates);
      },
    });
  };

  // Delete User Email Templates
  const deleteUserEmailTemplate = (templateId: number) => {
    useFetch(API_ENDPOINTS.DELETE_USER_EMAIL_TEMPLATE.url, "DELETE", {
      failureMessage: API_ENDPOINTS.DELETE_USER_EMAIL_TEMPLATE.failureMessage,
      config: {
        data: {
          templateId: templateId,
          useCaseType: props.otherProps?.templateType,
        },
      },
      thenCallBack: (_res) => {
        listUserEmailTemplateForBcBeta("");
        handleCloseDeleteModal();
      },
    });
  };

  type ExtendedWindow = typeof window & { debug: boolean };

  return (
    <>
      <Box display="flex" alignItems="center" gap={2} px={2} py={1.5} borderBottom={1} borderColor="grey.300">
        <Typography variant="subtitle2" color="textPrimary">
          Email Template
        </Typography>
        <TextField
          select
          size="small"
          label="Select Template"
          variant="outlined"
          fullWidth
          sx={{ width: 300 }}
          value={templateId || ""}
          error={!templateId && props.inputEditorState?.templateError ? true : false}
          helperText={!templateId && props.inputEditorState?.templateError ? "Please Select Template" : ""}
          SelectProps={{
            renderValue: (value) => {
              const filteredSelectedTemplate = emailTemplates.filter((item) => item.templateId === Number(value));

              if (filteredSelectedTemplate.length === 0) return "";

              return filteredSelectedTemplate[0].templateName;
            },
          }}
          onChange={async (e) => {
            const filteredSelectedTemplate = emailTemplates.filter(
              (item) => item.templateId === Number(e.target.value)
            );

            setTemplateId(Number(e.target.value));

            if (filteredSelectedTemplate.length === 0) {
              setEmailSubject("");
              setEditorContent("");
              return;
            }

            const { emailBody, emailSubject } = filteredSelectedTemplate[0];

            setEmailSubject(emailSubject);
            setEditorContent(emailBody);
          }}
        >
          {emailTemplates.length > 0 &&
            emailTemplates.map((item, itemInd: number) => (
              <MenuItem className="d_flex" key={itemInd} value={item.templateId}>
                {item.templateName}
                {!item?.default && (
                  <Delete
                    sx={{ ml: "auto" }}
                    onClick={() => {
                      setTemplateId(item?.templateId);
                      setIsOpenDeleteModal(true);
                    }}
                  />
                )}
              </MenuItem>
            ))}
          <MenuItem value="">Select Template</MenuItem>
        </TextField>
        <Button
          variant="text"
          startIcon={<Add />}
          color="primary"
          onClick={() => {
            setOpenEmailTemplate(true);
          }}
        >
          NEW TEMPLATE
        </Button>
      </Box>
      <DialogSection className="d_flex" sx={{ flexDirection: "column", px: 2, py: 3, gap: 2 }}>
        <TextField
          hidden={props.sectionsCustomizations?.subject === false}
          fullWidth
          size="small"
          label="Subject"
          variant="outlined"
          value={emailSubject}
          onChange={(e) => {
            setEmailSubject(e.target.value);
          }}
        />

        {(window as ExtendedWindow).debug && (
          <FormControlLabel
            control={
              <Android12Switch
                onChange={() => setOldEditorMode(!oldEditorMode)}
                onClick={(ev) => {
                  (ev.target as HTMLInputElement).checked ? setOldEditorMode(false) : setOldEditorMode(true);
                }}
                checked={oldEditorMode}
              />
            }
            label="Old Editor Mode"
          />
        )}

        {(window as ExtendedWindow).debug && (
          <Button
            variant="outlined"
            onClick={() => {
              console.log("editorContent \n\n", editorContent);
              console.log("templateId", templateId);
              console.log("emailSubject", emailSubject);
              window.alert("check console for editor content");
            }}
          >
            GET Editor Content
          </Button>
        )}

        <Box hidden={props.sectionsCustomizations?.editor === false} className="EditorBox">
          {oldEditorMode ? (
            <Editor
              selectedEmailTemplateContent={null}
              selectedTemplateName=""
              dynamicContent={editorContent}
              setDynamicContent={setEditorContent}
            />
          ) : (
            <NewEditor key={templateId} content={editorContent} setContent={setEditorContent} />
          )}
        </Box>

        <Box
          hidden={props.sectionsCustomizations?.uploadArea === false}
          display={"flex"}
          gap={1}
          sx={{ overflowX: "auto" }}
        >
          {files !== null &&
            files.length > 0 &&
            files.map((_file, fileInd) => (
              <Chip
                title={files[fileInd].name}
                key={files[fileInd].name}
                label={getShortName(files[fileInd].name)}
                onDelete={() => {
                  getShortName(files[fileInd].name);
                  files.splice(fileInd, 1);
                  setFiles([...files]);
                }}
                deleteIcon={<Delete sx={{ fontSize: 20, ":hover": { color: "#f118 !important" } }} />}
                sx={{
                  maxWidth: 200,
                  "&, span": {
                    textOverflow: "unset !important",
                  },
                  background: "rgba(84, 28, 76, 0.1) !important",
                  "&:hover": {
                    background: "rgba(84, 28, 76, 0.2) !important",
                  },
                }}
              />
            ))}
        </Box>

        <Box className="d_flex">
          <div style={{ height: 10 }} className="mb_10 flex_end">
            {copyAlert && (
              <Alert severity="success" sx={{ width: 200, height: 48, position: "relative", top: -20 }}>
                Copied!
              </Alert>
            )}
          </div>
        </Box>

        <Box className="d_flex" gap={2}>
          <Button
            hidden={props.sectionsCustomizations?.uploadArea === false}
            variant="outlined"
            color="primary"
            onClick={() => {
              if (inputRef.current) {
                inputRef.current?.click();
              }
              if (props.otherProps?.templateType === UseCaseType.balanceConfirmationBeta) {
                uiLogger(
                  uiLoggerNamesPartnerCommunication.UI_PC_BCB_SEND_VIEW_MAIL_UPLOAD_FILE_CLICK.functionName,
                  props.otherProps.companyId,
                  props.otherProps.branchCode,
                  { message: uiLoggerNamesPartnerCommunication.UI_PC_BCB_SEND_VIEW_MAIL_UPLOAD_FILE_CLICK.message }
                );
              }
            }}
            endIcon={<i className="fa-solid fa-upload" style={{ color: "#aaa" }} />}
          >
            Upload Local File
            <input
              ref={inputRef}
              hidden={true}
              multiple={true}
              type="file"
              onChange={(e) => {
                setFiles([...files, ...Array.from(e.target.files)]);
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </Button>

          {/* Dynamic Content */}
          <Autocomplete
            size="small"
            onChange={(_, value: any) => {
              if (value !== null) {
                navigator.clipboard.writeText(value);
                // insertDynamiccontent(value);
                setCopyAlert(true);
                setTimeout(() => {
                  setCopyAlert(false);
                }, 3000);
              }
            }}
            sx={{ width: 200 }}
            options={dynamicContentOptions}
            //   value={dynamicContent}
            renderInput={(params) => <TextField {...params} label="Dynamic Content" placeholder="Dynamic Content" />}
          />
        </Box>

        <Box
          hidden={props.sectionsCustomizations?.signature === false}
          className="d_flex"
          sx={{ p: 2, alignItems: "center", border: 1, borderColor: "#cccccc" }}
        >
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
                {userEmailSetting?.signature?.text}
              </Typography>
              {userEmailSetting?.signature?.image && (
                <img
                  src={`data:image/png;base64,${userEmailSetting?.signature?.image}`}
                  alt="signature"
                  height={40}
                  width={100}
                  style={{ objectFit: "contain" }}
                />
              )}
            </Box>
          </Box>
          <IconButton
            onClick={async () => {
              // logic
              const tab = "UserEmailSetting";
              // const authDialog = 1;

              const params = `tab=${tab}`;
              const uri = `/${actor.name}/reconSettings?${params}`;
              window.open(uri, "_blank")?.focus();
              setOpenUpdateEmailSettingDlg(true);
            }}
          >
            <Edit />
          </IconButton>
        </Box>
      </DialogSection>
      <CreateEmailTemplate
        open={openEmailTemplate}
        setOpen={setOpenEmailTemplate}
        listUserEmailTemplates={props.otherProps?.listUserEmailTemplates}
        templateType={props.otherProps?.templateType}
      />
      <EmailTemplateDeleteWarningModal
        handleDelete={async () => {
          if (deleteUserEmailTemplate) deleteUserEmailTemplate(templateId);
        }}
        handleDeletePdf={async () => {}}
      />
      <UpdateUserEmailSettingDialog open={openUpdateEmailSettingDlg} setOpen={setOpenUpdateEmailSettingDlg} />
    </>
  );
};

export default EmailEditorWithTemplate;
