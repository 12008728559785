import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useState } from "react";
import { User } from "../../PartnerCommunication/BalanceConfirmationBeta/MoveTaskToReconModal";
import { NdButton } from "../../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import { ALL_RELATIONSHIPS } from "./Interfaces";

export default function MISFilter({
  open,
  setOpen,
  allCategories,
  allLabels,
  allUsers,
  setAppliedFilters,
  setIsFilterApplied,
  appliedFilters,
}) {
  const [selectedCategories, setSelectedCategories] = useState<string[]>(appliedFilters?.categories || []);
  const [selectedRelationship, setSelectedRelationship] = useState<string>(appliedFilters?.relationship || null);
  const [selectedLabels, setSelectedLabels] = useState<string[]>(appliedFilters?.taskLabel || []);
  const [selectedApprovers, setSelectedApprovers] = useState<User[]>(appliedFilters?.approver || []);
  const [selectedPeriodDate, setSelectedPeriodDate] = useState<Date>(appliedFilters?.period || null);
  const [periodDateErrorText, setPeriodDateErrorText] = useState("");
  const [selectedActivityPeriodStart, setSelectedActivityPeriodStart] = useState<Date>(
    appliedFilters?.activityPeriod?.from || null
  );
  const [selectedActivityPeriodEnd, setSelectedActivityPeriodEnd] = useState<Date>(
    appliedFilters?.activityPeriod?.to || null
  );

  const handleFilterApply = () => {
    const appliedFilterObj = {};
    let isAnyFilterApplied = false;

    if (selectedCategories.length > 0) {
      isAnyFilterApplied = true;
      Object.assign(appliedFilterObj, { categories: selectedCategories });
    }
    if (selectedRelationship) {
      isAnyFilterApplied = true;
      Object.assign(appliedFilterObj, { relationship: selectedRelationship });
    }
    if (selectedLabels.length > 0) {
      isAnyFilterApplied = true;
      Object.assign(appliedFilterObj, { taskLabel: selectedLabels });
    }
    if (selectedApprovers.length > 0) {
      isAnyFilterApplied = true;
      Object.assign(appliedFilterObj, { approver: selectedApprovers });
    }
    if (selectedPeriodDate) {
      console.log(selectedPeriodDate, "lllinside");
      isAnyFilterApplied = true;
      Object.assign(appliedFilterObj, { period: selectedPeriodDate });
    }
    if (selectedActivityPeriodStart && selectedActivityPeriodEnd) {
      isAnyFilterApplied = true;
      Object.assign(appliedFilterObj, {
        activityPeriod: { from: selectedActivityPeriodStart, to: selectedActivityPeriodEnd },
      });
    }
    console.log(appliedFilterObj, "lllllmm");

    setAppliedFilters(appliedFilterObj);
    setIsFilterApplied(isAnyFilterApplied);
    setOpen(false);
  };

  const handleClearFilters = () => {
    setSelectedCategories([]);
    setSelectedLabels([]);
    setSelectedApprovers([]);
    setSelectedRelationship(null);
    setSelectedActivityPeriodEnd(null);
    setSelectedActivityPeriodStart(null);
    setSelectedPeriodDate(null);
    setIsFilterApplied(false);
    setOpen(false);
    setAppliedFilters({});
  };

  return (
    <Dialog open={open} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "600px" } }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "16px 24px" }}>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Apply Filter
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpen(false);
          }}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px 16px",
            }}
          >
            <Typography variant="subtitle2">Relationship</Typography>
            <Autocomplete
              sx={{ width: "320px", "& fieldset": { borderRadius: "4px" } }}
              value={selectedRelationship}
              fullWidth={true}
              onChange={(_, value) => {
                setSelectedRelationship(value);
              }}
              size="small"
              id="tags-outlined"
              options={ALL_RELATIONSHIPS || []}
              getOptionLabel={(option) => option}
              filterSelectedOptions={true}
              renderInput={(params) => <TextField {...params} label="Select Relationship" />}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px 16px",
            }}
          >
            <Typography variant="subtitle2">Category</Typography>
            <Autocomplete
              limitTags={3}
              sx={{ width: "320px", "& fieldset": { borderRadius: "4px" } }}
              value={selectedCategories}
              fullWidth={true}
              onChange={(_, value) => {
                setSelectedCategories(value);
              }}
              size="small"
              multiple={true}
              id="tags-outlined"
              options={allCategories || []}
              getOptionLabel={(option) => option}
              filterSelectedOptions={true}
              renderInput={(params) => <TextField {...params} label="Select Category" />}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px 16px",
            }}
          >
            <Typography variant="subtitle2">Label</Typography>
            <Autocomplete
              limitTags={3}
              sx={{ width: "320px", "& fieldset": { borderRadius: "4px" } }}
              value={selectedLabels}
              fullWidth={true}
              onChange={(_, value) => {
                setSelectedLabels(value);
              }}
              size="small"
              multiple={true}
              id="tags-outlined"
              options={allLabels || []}
              getOptionLabel={(option) => option}
              filterSelectedOptions={true}
              renderInput={(params) => <TextField {...params} label="Select Label" />}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px 16px",
            }}
          >
            <Typography variant="subtitle2">Period (CB Activity till date)</Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                sx={{ "& fieldset": { borderRadius: "4px" } }}
                label="Period"
                value={selectedPeriodDate}
                onError={(error) => {
                  if (error) setPeriodDateErrorText(error);
                  else setPeriodDateErrorText("");
                }}
                onChange={(date: Date) => setSelectedPeriodDate(date)}
                format="dd/MM/yyyy"
                slotProps={{
                  textField: {
                    helperText: periodDateErrorText && "Invalid Date",
                    sx: { width: "320px" },
                    size: "small",

                    InputProps: {
                      style: { borderRadius: "4px" },
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px 16px",
            }}
          >
            <Typography variant="subtitle2">Approver</Typography>
            <Autocomplete
              limitTags={3}
              sx={{ width: "320px", "& fieldset": { borderRadius: "4px" } }}
              value={selectedApprovers}
              fullWidth={true}
              onChange={(_, value) => {
                setSelectedApprovers(value);
              }}
              size="small"
              multiple={true}
              id="tags-outlined"
              options={allUsers || []}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions={true}
              renderInput={(params) => <TextField {...params} label="Select Approver" />}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px 16px",
            }}
          >
            <Typography variant="subtitle2">Activity Period</Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box sx={{ display: "flex", gap: 1, flexDirection: "row", width: "320px" }}>
                <DesktopDatePicker
                  sx={{ "& fieldset": { borderRadius: "4px" } }}
                  label="Start Date"
                  value={selectedActivityPeriodStart}
                  onError={(error) => {
                    if (error) setPeriodDateErrorText(error);
                    else setPeriodDateErrorText("");
                  }}
                  onChange={(date: Date) => setSelectedActivityPeriodStart(date)}
                  format="dd/MM/yyyy"
                  slotProps={{
                    textField: {
                      helperText: periodDateErrorText && "Invalid Date",
                      // sx: { width: "320px" },
                      size: "small",

                      InputProps: {
                        style: { borderRadius: "4px" },
                      },
                    },
                  }}
                />
                <DesktopDatePicker
                  sx={{ "& fieldset": { borderRadius: "4px" } }}
                  label="End Date"
                  value={selectedActivityPeriodEnd}
                  onError={(error) => {
                    if (error) setPeriodDateErrorText(error);
                    else setPeriodDateErrorText("");
                  }}
                  onChange={(date: Date) => setSelectedActivityPeriodEnd(date)}
                  format="dd/MM/yyyy"
                  slotProps={{
                    textField: {
                      helperText: periodDateErrorText && "Invalid Date",
                      // sx: { width: "320px" },
                      size: "small",

                      InputProps: {
                        style: { borderRadius: "4px" },
                      },
                    },
                  }}
                />
              </Box>
            </LocalizationProvider>
          </Box>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClearFilters} sx={{ color: "#00000099" }}>
          Clear Filter
        </Button>
        <NdButton variant="contained" onClick={handleFilterApply}>
          Apply
        </NdButton>
      </DialogActions>
    </Dialog>
  );
}
