import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import CustomToast from "src/Components/CustomToast";
import ReactTable from "src/Components/ReactTable/ReactTable";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { NdButton } from "../../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import AddFunnelReportLoading from "./AddFunnelReportLoading";
import AppliedFiltersSection from "./AppliedFiltersSection";
import ColumnVisibiltySection from "./ColumnVisibiltySection";
import FunnelMetricSection from "./FunnelMetricSection";
import {
  FUNNEL_METRIC_TYPES,
  FUNNEL_REPORT_ALL_COLUMNS,
  FUNNEL_REPORT_COLUMNS,
  MISFilters,
  REPORT_TYPES,
  VIEW_COLUMNS,
} from "./Interfaces";
import MISFilter from "./MISFilter";
import ReportPreviewLoading from "./ReportPreviewLoading";
import SetReportView from "./SetReportView";

export default function AddFunnelReport({
  allLabels,
  allCategories,
  allUsers,
  setPreviewReportDetails,
  previewReportDetails,
  listAllReports,
}) {
  const [reportName, setReportName] = useState("");
  const [showMISFilterModal, setShowMISFilterModal] = useState<boolean>(false);
  const [appliedFilters, setAppliedFilters] = useState<MISFilters>({});
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [reportData, setReportData] = useState([]);
  const [reportViews, setReportViews] = useState([]);
  const [isLoadingReport, setIsLoadingReport] = useState<boolean>(false);
  const [metric, setMetric] = useState({ byVolume: false, byValue: true });
  const [columnsVisibilty, setColumnsVisibilty] = useState([]);
  const [columnsOrder, setColumnsOrder] = useState<string[]>([]);
  const [isUpdatingPreview, setIsUpdatingPreview] = useState<boolean>(false);
  const [isSavingReport, setIsSavingReport] = useState<boolean>(false);
  const [tableHiddenColumns, setTableHiddenColumns] = useState({});

  const funnelReportColumns = useMemo(
    () => [
      {
        accessorKey: "metric",
        header: "Metric",
        size: 250,
        accessorFn: (row) => row.metric ?? "",
      },
      {
        accessorKey: "numberOfPartners",
        header: "Total Partners",
        size: 250,
        accessorFn: (row) => row.numberOfPartners ?? "",
      },
      {
        accessorKey: "partnersContacted",
        header: "Partners Contacted",
        size: 250,
        accessorFn: (row) => row.partnersContacted ?? "",
      },
      {
        accessorKey: "partnersResponded",
        header: "Partners Responded",
        size: 250,
        accessorFn: (row) => row.partnersResponded ?? "",
      },
      {
        accessorKey: "partnersConfirmedBalance",
        header: "Partner confirmed balance",
        size: 250,
        accessorFn: (row) => row.partnersConfirmedBalance ?? "",
      },
      {
        accessorKey: "partnersSharedLedger",
        header: "Partner Shared Ledger",
        size: 250,
        accessorFn: (row) => row.partnersSharedLedger ?? "",
      },
      {
        accessorKey: "reconDone",
        header: "Recon done",
        size: 250,
        accessorFn: (row) => row.reconDone ?? "",
      },
      {
        accessorKey: "CBDiff",
        header: "CB difference",
        size: 250,
        accessorFn: (row) => row.CBDiff ?? "",
      },
      {
        accessorKey: "ownBalance",
        header: "Own balance",
        size: 250,
        accessorFn: (row) => row.ownBalance ?? "",
      },
      {
        accessorKey: "partnerBalance",
        header: "Partner balance",
        size: 250,
        accessorFn: (row) => row.partnerBalance ?? "",
      },
      {
        accessorKey: "invoiceNotBookedByPartner",
        header: "Invoice Not booked By Partner",
        size: 250,
        accessorFn: (row) => row.invoiceNotBookedByPartner ?? "",
      },
      {
        accessorKey: "invoiceNotBookedByOwn",
        header: "Invoice not booked by Own",
        size: 250,
        accessorFn: (row) => row.invoiceNotBookedByOwn ?? "",
      },
      {
        accessorKey: "invoiceMisMatch",
        header: "Invoice Mismatch",
        size: 250,
        accessorFn: (row) => row.invoiceMisMatch ?? "",
      },
      {
        accessorKey: "paymentNotBookedByPartner",
        header: "Payment Not booked By Partner",
        size: 250,
        accessorFn: (row) => row.paymentNotBookedByPartner ?? "",
      },
      {
        accessorKey: "paymentNotBookedByOwn",
        header: "Payment not booked by Own",
        size: 250,
        accessorFn: (row) => row.paymentNotBookedByOwn ?? "",
      },
      {
        accessorKey: "paymentMisMatch",
        header: "Payment Mismatch",
        size: 250,
        accessorFn: (row) => row.paymentMisMatch ?? "",
      },

      {
        accessorKey: "dncnNotBookedByPartner",
        header: "DN/CN Not booked By Partner",
        size: 250,
        accessorFn: (row) => row.dncnNotBookedByPartner ?? "",
      },
      {
        accessorKey: "dncnNotBookedByOwn",
        header: "DN/CN not booked by Own",
        size: 250,
        accessorFn: (row) => row.dncnNotBookedByOwn ?? "",
      },
      {
        accessorKey: "dncnMisMatch",
        header: "DN/CN Mismatch",
        size: 250,
        accessorFn: (row) => row.dncnMisMatch ?? "",
      },
      {
        accessorKey: "tdsNotBookedByPartner",
        header: "Payment Not booked By Partner",
        size: 250,
        accessorFn: (row) => row.tdsNotBookedByPartner ?? "",
      },
      {
        accessorKey: "tdsNotBookedByOwn",
        header: "Payment not booked by Own",
        size: 250,
        accessorFn: (row) => row.tdsNotBookedByOwn ?? "",
      },
      // {
      //   accessorKey: "invoiceReconciled",
      //   header: "Invoice Reconciled",
      //   size: 250,
      //   accessorFn: (row) => row.invoiceReconciled ?? "",
      // },
      // {
      //   accessorKey: "paymentReconciled",
      //   header: "Payment Reconciled",
      //   size: 250,
      //   accessorFn: (row) => row.paymentReconciled ?? "",
      // },
      {
        accessorKey: "taskLabel",
        header: "Task Label",
        size: 250,
        accessorFn: (row) => row.taskLabel ?? "",
      },
      {
        accessorKey: "category",
        header: "Category",
        size: 250,
        accessorFn: (row) => row.category ?? "",
      },
      {
        accessorKey: "approver",
        header: "Approver",
        size: 250,
        accessorFn: (row) => row.approver ?? "",
      },
      {
        accessorKey: "activityPeriod",
        header: "Activity Period",
        size: 250,
        accessorFn: (row) => row.activityPeriod ?? "",
      },
      {
        accessorKey: "company",
        header: "Company",
        size: 250,
        accessorFn: (row) => row.company ?? "",
      },
      {
        accessorKey: "branch",
        header: "Branch",
        size: 250,
        accessorFn: (row) => row.branch ?? "",
      },
      {
        accessorKey: "relationship",
        header: "Relationship",
        size: 250,
        accessorFn: (row) => row.relationship ?? "",
      },
    ],
    []
  );

  const handleColumnsVisibiltyToggle = (index: number) => {
    setColumnsVisibilty((prevColumns) =>
      prevColumns.map((column, i) => (i === index ? { ...column, isVisible: !column.isVisible } : column))
    );
  };

  const getReportDetails = () => {
    setIsLoadingReport(true);
    useFetch(API_ENDPOINTS.GET_REPORT_DETAILS_PARTNER_RISK_MANAGEMENT.url, "POST", {
      failureMessage: API_ENDPOINTS.GET_REPORT_DETAILS_PARTNER_RISK_MANAGEMENT.failureMessage,
      data: { id: previewReportDetails.id, reportType: REPORT_TYPES.Funnel },
      thenCallBack: (res) => {
        setReportData(res.data.reportData || []);
        setReportName(res.data.reportName);
        setReportViews(res.data.views || []);
        setAppliedFilters(res.data.filters || {});
        if (Object.keys(res.data.filters).length > 0) {
          setIsFilterApplied(true);
        }
        setColumnsOrder(res.data.columnsOrder || []);
        const rawCols = res.data.columnsOrder || [];
        const hiddenColumnsObj = [...FUNNEL_REPORT_ALL_COLUMNS, ...VIEW_COLUMNS]
          .filter((item) => !rawCols.includes(item))
          .reduce((acc, key) => {
            acc[key] = false;
            return acc;
          }, {});

        console.log(hiddenColumnsObj, "kk");

        setTableHiddenColumns(hiddenColumnsObj);

        setMetric({
          byVolume: res.data.metricTypes.includes(FUNNEL_METRIC_TYPES.Volume) ? true : false,
          byValue: res.data.metricTypes.includes(FUNNEL_METRIC_TYPES.Value) ? true : false,
        });
        const hiddenColumns = res.data.hiddenColumns || [];
        const columnVisibilityInitial = FUNNEL_REPORT_ALL_COLUMNS.map((columnKey) => ({
          key: columnKey,
          label: FUNNEL_REPORT_COLUMNS[columnKey],
          isVisible: !hiddenColumns.includes(columnKey), // If not in hiddenColumns, set isVisible to true
        }));

        setColumnsVisibilty(columnVisibilityInitial);
        setIsLoadingReport(false);
      },
      catchCallBack: () => {
        setIsLoadingReport(false);
      },
    });
  };

  const getReportPreview = () => {
    const metricPayload = [];
    if (metric.byVolume) {
      metricPayload.push(FUNNEL_METRIC_TYPES.Volume);
    }
    if (metric.byValue) {
      metricPayload.push(FUNNEL_METRIC_TYPES.Value);
    }
    if (metricPayload.length === 0) {
      toast.error(<CustomToast message="Please Select Atleast 1 metric" />);
      return;
    }
    setIsUpdatingPreview(true);
    useFetch(API_ENDPOINTS.GET_REPORT_PREVIEW_PARTNER_RISK_MANAGEMENT.url, "POST", {
      failureMessage: API_ENDPOINTS.GET_REPORT_PREVIEW_PARTNER_RISK_MANAGEMENT.failureMessage,
      showSuccessToast: true,
      data: {
        reportName: reportName,
        reportType: REPORT_TYPES.Funnel,
        views: reportViews.filter((view) => view !== ""),
        filters: appliedFilters,
        hiddenColumns: columnsVisibilty.filter((column) => !column.isVisible).map((column) => column.key),
        metricTypes: metricPayload,
      },
      thenCallBack: (res) => {
        setReportData(res.data.reportData || []);
        setColumnsOrder(res.data.columnsOrder || []);
        const rawCols = res.data.columnsOrder || [];
        const hiddenColumnsObj = [...FUNNEL_REPORT_ALL_COLUMNS, ...VIEW_COLUMNS]
          .filter((item) => !rawCols.includes(item))
          .reduce((acc, key) => {
            acc[key] = false;
            return acc;
          }, {});
        setTableHiddenColumns(hiddenColumnsObj);
        setIsUpdatingPreview(false);
      },
      catchCallBack: () => {
        setIsUpdatingPreview(false);
      },
    });
  };

  const handleSaveReport = () => {
    const metricPayload = [];
    if (metric.byVolume) {
      metricPayload.push(FUNNEL_METRIC_TYPES.Volume);
    }
    if (metric.byValue) {
      metricPayload.push(FUNNEL_METRIC_TYPES.Value);
    }
    if (metricPayload.length === 0) {
      toast.error(<CustomToast message="Please Select Atleast 1 metric" />);
      return;
    }
    setIsSavingReport(true);
    useFetch(API_ENDPOINTS.SAVE_MIS_REPORT_PARTNER_RISK_MANAGEMENT.url, "POST", {
      failureMessage: API_ENDPOINTS.SAVE_MIS_REPORT_PARTNER_RISK_MANAGEMENT.failureMessage,
      showSuccessToast: true,
      data: {
        id: previewReportDetails.reportAction === "editReport" ? previewReportDetails.id : null,
        reportName: reportName,
        reportType: REPORT_TYPES.Funnel,
        views: reportViews.filter((view) => view !== ""),
        filters: appliedFilters,
        hiddenColumns: columnsVisibilty.filter((column) => !column.isVisible).map((column) => column.key),
        metricTypes: metricPayload,
      },
      thenCallBack: () => {
        setIsSavingReport(false);
        setPreviewReportDetails({ id: null, reportType: null, reportAction: null });
        listAllReports();
      },
      catchCallBack: () => {
        setIsSavingReport(false);
      },
    });
  };
  useEffect(() => {
    getReportDetails();
  }, []);

  return (
    <>
      {isLoadingReport ? (
        <AddFunnelReportLoading />
      ) : (
        <>
          {/* this is top bar */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "12px 16px",
              backgroundColor: "#ffffff",
              border: "1px solid rgba(0, 0, 0, 0.12)",
              marginTop: 1,
            }}
          >
            <TextField
              label="Report Name"
              placeholder="Report Name"
              value={reportName}
              onChange={(e) => setReportName(e.target.value)}
              variant="outlined"
              size="small"
              sx={{
                width: "50%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: 1,
                },
              }}
            />
            <Box>
              <NdButton
                disabled={isSavingReport || isUpdatingPreview}
                variant="contained"
                onClick={handleSaveReport}
                sx={{
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "24px",
                  letterSpacing: "0.4px",
                  padding: "6px 16px",
                  marginRight: "8px",
                }}
              >
                {isSavingReport && <LoadingIcon loading={true} />}
                {previewReportDetails.reportAction === "editReport" ? "Save" : "Add"} Report
              </NdButton>
              <Button
                variant="outlined"
                onClick={() => {
                  setPreviewReportDetails({ id: null, reportType: null, reportAction: null });
                }}
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  ml: "auto",
                  padding: "6px 16px",
                  borderRadius: "4px !important",
                  color: "rgba(0, 0, 0, 0.87)",
                  borderColor: "rgba(0, 0, 0, 0.12)",
                  "&:hover, &.Mui-focusVisible": { borderColor: "rgba(0, 0, 0, 0.12)" },
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
          {/* this is for 2nd row */}
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box
              sx={{
                padding: "12px 16px",
                backgroundColor: "#fafafa",
                border: "1px solid rgba(0, 0, 0, 0.12)",
                width: "75%",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 500,
                  fontSize: "14px",
                  letterSpacing: "0.1px",
                  lineHeight: "157%",
                }}
              >
                Default Funnel Report
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  letterSpacing: "0.4px",
                  lineHeight: "166%",
                }}
              >
                Below is the default funnel level report, add customization like filter and report view to change it as
                per your requirement
              </Typography>
            </Box>
            <Box
              sx={{
                padding: "12px 16px",
                backgroundColor: "#fafafa",
                border: "1px solid rgba(0, 0, 0, 0.12)",
                width: "25%",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 500,
                  fontSize: "14px",
                  letterSpacing: "0.1px",
                  lineHeight: "157%",
                }}
              >
                Customise Report
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  letterSpacing: "0.4px",
                  lineHeight: "166%",
                  color: "#1976d2",
                  textDecoration: "none",
                }}
              >
                How to customise report?
              </Typography>
            </Box>
          </Box>
          {/* this for 3rd row */}
          <Box
            sx={{ display: "flex", flexDirection: "row", border: "1px solid rgba(0, 0, 0, 0.12)", background: "#fff" }}
          >
            {/* For table */}
            {
              <Box sx={{ width: "75%", padding: 1 }}>
                {isUpdatingPreview ? (
                  <ReportPreviewLoading />
                ) : (
                  <ReactTable
                    columns={funnelReportColumns}
                    rows={reportData}
                    enableRowSelection={false}
                    enableColumnFilters={false}
                    enableGrouping={false}
                    enableColumnPinning={false}
                    enableExpanding={true}
                    enableColumnDragging={false}
                    enableExpandAll={true}
                    enableDensityToggle={false}
                    enableColumnOrdering={false}
                    internalActionsOrder={["fullScreenToggle"]}
                    filterFromLeafRows={true}
                    initialStateColumnOrder={columnsOrder}
                    initialStateColumnVisibility={tableHiddenColumns}
                  />
                )}
              </Box>
            }
            {/* For table right content */}
            <Box sx={{ width: "25%", background: "#fff", border: "1px solid rgba(0, 0, 0, 0.12)" }}>
              <Box sx={{ height: "68vh", overflow: "auto" }}>
                {/* Metric section */}
                {previewReportDetails.reportType === REPORT_TYPES.Funnel && (
                  <FunnelMetricSection metric={metric} setMetric={setMetric} />
                )}
                <Divider />
                {/* Group By Section */}
                <SetReportView reportViews={reportViews} setReportViews={setReportViews} />
                <Divider />
                {/* Filter section */}
                <Box
                  sx={{
                    padding: 2,
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                      }}
                    >
                      <FilterListIcon color="primary" />
                      <Typography
                        variant="subtitle2"
                        sx={{
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.1px",
                          lineHeight: "157%",
                          color: "#000000de",
                        }}
                      >
                        Filter Data
                      </Typography>
                    </Box>
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{
                        fontWeight: 500,
                        fontSize: "13px",
                        letterSpacing: "0.46px",
                        padding: "4px 10px",
                        borderRadius: "4px !important",
                      }}
                      onClick={() => {
                        setShowMISFilterModal(true);
                      }}
                    >
                      + Add
                    </Button>
                  </Box>
                  {isFilterApplied && (
                    <AppliedFiltersSection
                      appliedFilters={appliedFilters}
                      setShowMISFilterModal={setShowMISFilterModal}
                      setAppliedFilters={setAppliedFilters}
                      setIsFilterApplied={setIsFilterApplied}
                    />
                  )}
                </Box>
                <Divider />
                {/* Column Visibilty section */}
                <ColumnVisibiltySection
                  columnsVisibilty={columnsVisibilty}
                  handleColumnsVisibiltyToggle={handleColumnsVisibiltyToggle}
                />
              </Box>
              {/* Update report Preview Button */}
              <Box sx={{ padding: "12px 16px", background: "#FAFAFA", borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}>
                <Button
                  disabled={isUpdatingPreview || isSavingReport}
                  color="primary"
                  variant="contained"
                  sx={{
                    width: "100%",
                    borderRadius: "4px !important",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.4px",
                  }}
                  onClick={() => {
                    getReportPreview();
                  }}
                >
                  {isUpdatingPreview && <LoadingIcon loading={true} />}
                  Update Report View
                </Button>
              </Box>
            </Box>
          </Box>
          {showMISFilterModal && (
            <MISFilter
              open={showMISFilterModal}
              setOpen={setShowMISFilterModal}
              allCategories={allCategories}
              allLabels={allLabels}
              allUsers={allUsers}
              appliedFilters={appliedFilters}
              setAppliedFilters={setAppliedFilters}
              setIsFilterApplied={setIsFilterApplied}
            />
          )}
        </>
      )}
    </>
  );
}
