import CachedIcon from "@mui/icons-material/Cached";
import DownloadIcon from "@mui/icons-material/Download";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { Alert, Button, Grid } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import useFetch from "src/Components/Common/useFetch";
import { limitValue } from "src/entity/recon-entity/ReconInterfaces";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import Loader from "../../../Common/Loader";
import { LoggedInSkeleton } from "../../../Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "../../../Common/TopNavBar";
import { userContext } from "../../../Contexts/userContext";
import BpDashboardTable from "../../../ReactTable/BpDashboardTable";
import ReconInsightsSection from "../ReconInsightsSection/ReconInsightsSection";
import { formatNumberINR } from "../utils";
import ARReconInsightsFilter from "./ARReconInsightsFilter";

type ColDef = ColumnDef<any> & {
  sticky?: "left" | "right";
};

const ARReconInsights = () => {
  const { actor } = useContext(userContext);
  const location = useLocation<any>();
  const [loader, setLoader] = useState<boolean>(true);
  const [selectedSegment, setSelectedSegment] = useState<string>("Overview");
  const [rowsDataPartnerView, setRowsDataPartnerView] = useState<any>([]);
  const [storeRowsDataPartnerView, setStoreRowsDataPartnerView] = useState<any>([]);
  const [rowsDataOverview, setRowsDataOverview] = useState<any>([]);
  const [selectedMetricName, setSelectedMetricName] = useState<string>(null);
  const [isFilterAppliedPartnerViewFilter, setIsFilterAppliedPartnerViewFilter] = useState<boolean>(false);
  const [openPartnerViewFilter, setOpenPartnerViewFilter] = useState<boolean>(false);
  const storeAllCategories = useRef<any>(null);
  const offsetValue = useRef<number>(0);
  const [downloadARReconInsightsReportBase64, setDownloadARReconInsightsReportBase64] = useState<string>(null);

  const columnDefinitionPartnerView: ColDef[] = [
    {
      header: () => <div className="borderRight">Partner Name</div>,
      id: "Business partner name",
      sticky: "left",
      accessorFn: (row: any) => row.businessPartnerName?.toLowerCase(),
      cell: ({ row }: any) => <div>{row.original.businessPartnerName}</div>,
      maxSize: 180,
      minSize: 180,
      size: 180,
    },
    {
      header: () => (
        <div className={selectedMetricName === "Invoices missed by buyer" ? "highlight_column" : null}>
          Invoice missed by Buyer
        </div>
      ),
      // header: "Missing debit/Extra credit",
      id: "Invoices missed by buyer",
      // accessor: (row: any) => formatNumberINR(row.invoiceMissedByBuyer),
      accessorKey: "invoiceMissedByBuyer",
      maxSize: 180,
      minSize: 180,
      size: 180,
      cell: ({ row }: any) => <div>{formatNumberINR(row.original.invoiceMissedByBuyer)}</div>,
    },
    {
      header: () => (
        <div className={selectedMetricName === "Missing debit/Extra credit" ? "highlight_column" : null}>
          Missing Debit/Extra Credit
        </div>
      ),
      id: "Missing debit/Extra credit",
      // accessor: (row: any) => formatNumberINR(row.missingDebitExtraCredit),
      accessorKey: "missingDebitExtraCredit",
      maxSize: 180,
      minSize: 180,
      size: 180,
      cell: ({ row }: any) => <div>{formatNumberINR(row.original.missingDebitExtraCredit)}</div>,
    },
    {
      header: () => (
        <div className={selectedMetricName === "Incorrect amount" ? "highlight_column" : null}>Incorrect Amount</div>
      ),
      id: "Incorrect amount",
      // accessor: (row: any) => formatNumberINR(row.incorrectAmount),
      accessorKey: "incorrectAmount",
      maxSize: 180,
      minSize: 180,
      size: 180,
      cell: ({ row }: any) => <div>{formatNumberINR(row.original.incorrectAmount)}</div>,
    },
    {
      header: () => (
        <div className={selectedMetricName === "Potential missed Debit Notes" ? "highlight_column" : null}>
          Potential missed Debit Notes
        </div>
      ),
      id: "Potential missed Debit Notes",
      // accessor: (row: any) => formatNumberINR(row.potentialMissedDebitNote),
      accessorKey: "potentialMissedDebitNote",
      maxSize: 180,
      minSize: 180,
      size: 180,
      cell: ({ row }: any) => <div>{formatNumberINR(row.original.potentialMissedDebitNote)}</div>,
    },
    {
      header: () => (
        <div className={selectedMetricName === "TDS value GAP" ? "highlight_column" : null}>TDS Value Gap</div>
      ),
      id: "TDS value GAP",
      // accessor: (row: any) => formatNumberINR(row.tdsValueGap),
      accessorKey: "tdsValueGap",
      maxSize: 180,
      minSize: 180,
      size: 180,
      cell: ({ row }: any) => <div>{formatNumberINR(row.original.tdsValueGap)}</div>,
    },
    {
      header: () => (
        <div className={selectedMetricName === "Net Invoice value reconciled" ? "highlight_column" : null}>
          Net Invoice Value Reconciled
        </div>
      ),
      id: "Net Invoice value reconciled",
      // accessor: (row: any) => formatNumberINR(row.invoiceValueReconciled),
      accessorKey: "invoiceValueReconciled",
      maxSize: 180,
      minSize: 180,
      size: 180,
      cell: ({ row }: any) => <div>{formatNumberINR(row.original.invoiceValueReconciled)}</div>,
    },
    {
      header: () => <div className={selectedMetricName === "Match %" ? "highlight_column" : null}>Match %</div>,
      id: "Match %",
      accessorKey: "matchPercent",
      maxSize: 180,
      minSize: 180,
      size: 180,
      cell: ({ row }: any) => (
        <div>{row.original.matchPercent ? row.original.matchPercent?.toFixed(2) : row.original.matchPercent}</div>
      ),
    },
    {
      header: "Last recon date",
      accessorKey: "lastReconDate",
      maxSize: 180,
      minSize: 180,
      size: 180,
    },
    {
      header: () => <div className="borderLeft">View recon</div>,
      id: "View recon",
      accessorKey: "businessPartnerId",
      maxSize: 70,
      minSize: 70,
      size: 70,
      sticky: "right",
      cell: ({ row }: any) => (
        <button
          style={{ background: "none", border: "none", cursor: "pointer" }}
          onClick={() => {
            const { businessPartnerId, businessPartnerName } = row.original;
            const params = `bpId=${businessPartnerId}&bpName=${btoa(businessPartnerName)}`;
            const uri = `/${actor.name}/recon360/Summary/Ledger?${params}`;
            window.open(uri, "_blank")?.focus();
          }}
        >
          <CachedIcon />
        </button>
      ),
    },
  ];

  useEffect(() => {
    getReconMisAPReconInsights();
    // eslint-disable-next-line
  }, []);

  const getReconMisAPReconInsights = () => {
    useFetch(API_ENDPOINTS.GET_RECON_INSIGHTS.url, "POST", {
      failureMessage: API_ENDPOINTS.GET_RECON_INSIGHTS.failureMessage,
      data: {
        viewLevel: "AccountsReceivable",
        limit: limitValue,
        offset: offsetValue.current,
      },
      thenCallBack: (response) => {
        if (response.data.dataObj.partnerLevelData.length > 0) {
          setRowsDataPartnerView([...rowsDataPartnerView, ...response.data.dataObj.partnerLevelData]);
          setStoreRowsDataPartnerView([...storeRowsDataPartnerView, ...response.data.dataObj.partnerLevelData]);
          offsetValue.current = offsetValue.current + limitValue;
        }
        // storeAllCategories.current = ["a", "b", "c", "d"];

        setRowsDataOverview(response.data.dataObj.overviewLevelData);
        setDownloadARReconInsightsReportBase64(response.data?.reportWorkbookBase64);
        setLoader(false);
      },
      catchCallBack: () => {
        setLoader(false);
      },
    });
  };

  const onClickCircle = (metricName: string) => {
    setSelectedMetricName(metricName);
    setSelectedSegment("Partner view");
  };

  const downloadARReconInsightsReport = () => {
    const excelData = Buffer.from(downloadARReconInsightsReportBase64, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    saveAs(blob, `${actor.name} AR Recon Insights Report.xlsx`);
  };

  return (
    <LoggedInSkeleton
      topBarButtons={getReconTopBarButtons("AR Recon Insights", actor.name, location?.state?.openCollapseObj, actor)}
    >
      {loader ? (
        <Loader />
      ) : (
        <Grid>
          <Grid>
            <Alert severity="warning">
              This page is going under revamp. Data being shown has been frozen since 26-07-2023. For more details,
              kindly contact your Key Account Manager/Customer Success Manager
            </Alert>
          </Grid>
          <Grid className=" space_between mb_25 mt_15">
            <div>
              <Button
                variant="contained"
                className={`${selectedSegment === "Overview" ? "theme_btn" : "theme_outline_btn"} width_180`}
                onClick={() => {
                  setSelectedSegment("Overview");
                  setSelectedMetricName(null);
                  // getReconMisAPReconInsights("Overview");
                }}
              >
                Overview
              </Button>

              <Button
                variant="contained"
                className={`${selectedSegment === "Partner view" ? "theme_btn" : "theme_outline_btn"} width_180 ml_20`}
                onClick={() => {
                  setSelectedSegment("Partner view");
                  // getReconMisAPReconInsights("Partner view");
                }}
              >
                Partner view
              </Button>
            </div>
            <div className="d_flex">
              {selectedSegment === "Partner view" && (
                <div>
                  <Button
                    variant="contained"
                    className="theme_btn width_180"
                    onClick={() => {
                      setOpenPartnerViewFilter(true);
                    }}
                  >
                    Filter
                    <span className="vertical_center_align pl_10">
                      {isFilterAppliedPartnerViewFilter ? <FilterAltIcon /> : <FilterAltOffIcon />}
                    </span>
                  </Button>
                </div>
              )}
              <div>
                <Button
                  variant="contained"
                  className="theme_btn ml_20"
                  onClick={() => {
                    downloadARReconInsightsReport();
                  }}
                >
                  <DownloadIcon />
                </Button>
              </div>
            </div>
          </Grid>
          {selectedSegment === "Overview" && (
            <Grid>
              <ReconInsightsSection
                rowsDataOverview={rowsDataOverview}
                onClickCircle={(metric: string) => {
                  onClickCircle(metric);
                }}
                from={"reconMIS"}
              />
            </Grid>
          )}
          {selectedSegment === "Partner view" && (
            <Grid>
              <Grid>
                <BpDashboardTable
                  columns={columnDefinitionPartnerView}
                  rows={rowsDataPartnerView}
                  sortEnable={true}
                  reconInsightSortOn={selectedMetricName}
                  fromTab="reconMIS"
                  callApiOnPagination={() => {
                    getReconMisAPReconInsights();
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
      <ARReconInsightsFilter
        openPartnerViewFilter={openPartnerViewFilter}
        setOpenPartnerViewFilter={setOpenPartnerViewFilter}
        allCategories={storeAllCategories.current}
        rowsDataPartnerView={rowsDataPartnerView}
        setRowsDataPartnerView={setRowsDataPartnerView}
        storeRowsDataPartnerView={storeRowsDataPartnerView}
        setIsFilterAppliedPartnerViewFilter={setIsFilterAppliedPartnerViewFilter}
      />
    </LoggedInSkeleton>
  );
};

export default ARReconInsights;
