import AddIcon from "@mui/icons-material/Add";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import FilterAltIcon from "@mui/icons-material/FilterAlt";
// import MailIcon from "@mui/icons-material/Mail";
import { Box } from "@mui/material";
import React from "react";
import { NdButton } from "../../PartnerCommunication/MsmePartnerPortal/CommonComponents";

export default function Header({ setShowAddNewReportModal }) {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", gap: "32px", marginTop: 2 }}>
      <Box sx={{ display: "flex", gap: "8px" }}>
        {/* <Button
          variant="contained"
          startIcon={<FilterAltIcon />}
          sx={{
            backgroundColor: "#1976d2",
            borderRadius: "4px !important",
            color: "#fff",
            textTransform: "uppercase",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "24px",
            letterSpacing: "0.4px",
          }}
          onClick={() => setShowMISFilterModal(true)}
        >
          Filter
        </Button> */}
      </Box>
      <Box sx={{ display: "flex", gap: "8px" }}>
        <NdButton
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setShowAddNewReportModal(true);
          }}
        >
          Add New Report
        </NdButton>
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            borderRadius: "4px",
            border: "1px solid #541c4c80",
          }}
        >
          <Button
            startIcon={<MailIcon />}
            sx={{
              flexGrow: 1,
              color: "#541c4c",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "24px",
              letterSpacing: "0.4px",
            }}
          >
            Mail Reports
          </Button>
          <Divider orientation="vertical" flexItem sx={{ borderColor: "#541c4c80" }} />
          <Button
            startIcon={<CheckCircleIcon />}
            sx={{
              flexGrow: 1,
              color: "#541c4c",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "24px",
              letterSpacing: "0.4px",
            }}
          >
            Task Repository
          </Button>
        </Box> */}
      </Box>
    </Box>
  );
}
