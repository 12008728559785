import ViewStreamIcon from "@mui/icons-material/ViewStream";
import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import React from "react";

export default function FunnelMetricSection({ setMetric, metric }) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMetric({ ...metric, [event.target.name]: event.target.checked });
  };
  return (
    <Box
      sx={{
        padding: 2,
        gap: 1.25,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <ViewStreamIcon color="primary" />
        <Typography
          variant="subtitle2"
          sx={{
            fontFamily: "Roboto",
            fontWeight: 500,
            fontSize: "14px",
            letterSpacing: "0.1px",
            lineHeight: "157%",
            color: "#000000de",
          }}
        >
          Select Metric (Required)
        </Typography>
      </Box>
      <FormGroup sx={{ paddingLeft: 2 }}>
        <FormControlLabel
          control={<Checkbox checked={metric.byVolume} onChange={handleChange} name="byVolume" />}
          label="By Volume (Partner Count)"
          sx={{
            "& .MuiFormControlLabel-label": {
              fontWeight: 400,
              fontSize: "16px",
              letterSpacing: "0.15px",
              lineHeight: "150%",
              color: "#000000de",
            },
          }}
        />
        <FormControlLabel
          control={<Checkbox checked={metric.byValue} onChange={handleChange} name="byValue" />}
          label="By Value (Own Closing Balance)"
          sx={{
            "& .MuiFormControlLabel-label": {
              fontWeight: 400,
              fontSize: "16px",
              letterSpacing: "0.15px",
              lineHeight: "150%",
              color: "#000000de",
            },
          }}
        />
      </FormGroup>
    </Box>
  );
}
