import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Radio,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { NdButton } from "../../PartnerCommunication/MsmePartnerPortal/CommonComponents";

export default function AddNewReportModal({ open, setOpen, setPreviewReportDetails }) {
  const [selectedReportType, setSelectedReportType] = useState<string | null>(null);

  const handleRadioChange = (value: string) => {
    setSelectedReportType(value);
  };
  return (
    <Dialog open={open} sx={{ borderRadius: "4px" }}>
      <Paper
        sx={{
          width: 600,
          borderRadius: "4px",
          borderColor: "#dadada",
          borderWidth: "1px",
          borderStyle: "solid",
        }}
      >
        <Box sx={{ padding: "16px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography
            variant="h6"
            sx={{ fontWeight: 500, fontSize: "20px", letterSpacing: "0.15px", lineHeight: "160%" }}
          >
            Add New Report
          </Typography>
          <IconButton size="small" onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box sx={{ padding: "16px" }}>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 400, fontSize: "16px", letterSpacing: "0.15px", lineHeight: "175%" }}
          >
            Choose the type of report you want to add
          </Typography>
          <List>
            {["Funnel"].map((reportName) => (
              <ListItem key={reportName} sx={{ paddingY: "8px", alignItems: "flex-start" }}>
                <ListItemIcon>
                  <Radio checked={selectedReportType === reportName} onChange={() => handleRadioChange(reportName)} />
                </ListItemIcon>
                <ListItemText
                  primary={`${reportName} Report`}
                  secondary={
                    reportName === "Status"
                      ? "Displays partner counts, CB differences, and average time spent at each status level"
                      : "Provides an end-to-end view by value or volume across the funnel stages"
                  }
                  primaryTypographyProps={{
                    sx: {
                      fontWeight: 400,
                      fontSize: "16px",
                      letterSpacing: "0.15px",
                      lineHeight: "150%",
                    },
                  }}
                  secondaryTypographyProps={{
                    sx: {
                      fontWeight: 400,
                      fontSize: "14px",
                      letterSpacing: "0.17px",
                      lineHeight: "143%",
                    },
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
        <Divider />
        <Box sx={{ padding: "16px", backgroundColor: "#f1f1f1", display: "flex", justifyContent: "flex-end" }}>
          <NdButton
            disabled={selectedReportType === null}
            variant="contained"
            sx={{
              paddingY: "8px",
              paddingX: "22px",
              fontWeight: 500,
              fontSize: "15px",
              letterSpacing: "0.46px",
              lineHeight: "26px",
            }}
            onClick={() => {
              setPreviewReportDetails({
                id: null,
                reportType: selectedReportType && selectedReportType.toLowerCase(),
                reportAction: "addReport",
              });
              setOpen(false);
            }}
          >
            Next
          </NdButton>
        </Box>
      </Paper>
    </Dialog>
  );
}
