import AttachFileIcon from "@mui/icons-material/AttachFile";
import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  ListItemIcon,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Buffer } from "buffer";
import saveAs from "file-saver";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import CustomToast from "src/Components/CustomToast";
import ReactTable, { ColDef } from "src/Components/ReactTable/ReactTable";
import { TicketDetails, TicketFollowUpType } from "src/entity/recon-entity/ReconInterfaces";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { isOverFlowing } from "src/Utils/Common/CheckTextOverflow";
import { filterDateBetweenStartAndEndDate, formatDate, formatDateTime24Hours } from "src/Utils/DateUtils";
import { uiLoggerNamesWorkflow } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID } from "../PartnerCommunication/CommonLegacy/CommonComponents";
import { NdButton } from "../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import { Recon360Context } from "../Recon360";
import { TabPanel } from "../ReconSettings/ReconSettings";
import BulkTasksModal from "./BulkTasksModal";
import { TicketStatuses } from "./CurrentTicket";
import DiscardTicketModal from "./DiscardTicketModal";
import EditTaskModal from "./EditTaskModal";
import SaveTicketModal from "./SaveTicketModal";
import TaskAttachmentsModal from "./TaskAttachmentsModal";
import { NAKAD_SERVICE } from "./TasksHistoryModal";
import "./ViewTickets.scss";
import ViewTicketsFilter from "./ViewTicketsFilter";

const filter = createFilterOptions<any>();

export enum BackendTicketStatuses {
  Saved = "Saved Ticket",
  Discarded = "Discarded Ticket",
  Cleared = "Cleared Ticket",
}
export enum UITicketStatuses {
  Save = "Save Ticket",
  Discard = "Discard Ticket",
  Clear = "Clear Ticket",
}
export enum TicketMetaStatuses {
  Saved = "Saved",
  Discarded = "Discarded",
  Cleared = "Cleared",
  InProgress = "In Progress",
}
enum TicketViewType {
  All = "All",
  Ongoing = "Ongoing",
  Previous = "Previous",
  Discarded = "Discarded",
}
export const mappingForUiLogStatuses = {
  "Both Side Ledger Pending": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_BOTH_SIDE_LEDGER_PENDING_CLICK,
  "Partner Ledger Pending": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_PARTNER_LEDGER_PENDING_CLICK,
  "Own Ledger Pending": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_OWN_LEDGER_PENDING_CLICK,
  "Issue in Ledger": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_ISSUE_IN_LEDGER_CLICK,
  "Yet to Start": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_YET_TO_START_CLICK,
  "Recon in Progress": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_RECON_IN_PROGRESS_CLICK,
  "Maker Passed": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_MAKER_PASSED_CLICK,
  "Checker Passed": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_CHECKER_PASSED_CLICK,
  "Checker Rejected": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_CHECKER_REJECTED_CLICK,
  "Sent to Approver": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_SENT_TO_APPROVER_CLICK,
  "Approver Passed": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_APPROVER_PASSED_CLICK,
  "Approver Rejected": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_APPROVER_REJECTED_CLICK,
  "Technical Issue": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_TECHNICAL_ISSUE_CLICK,
  Dropped: uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_DROPPED_CLICK,
  "Saved Ticket": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_SAVE_TICKET_CLICK,
  "Discarded Ticket": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_DISCARD_TICKET_CLICK,
  "Recon Sent to Partner": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_RECON_SENT_TO_PARTNER_CLICK,
  "Work in Progress- Action items": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_WORK_IN_PROGRESS_ACTION_ITEMS_CLICK,
  "Sign-off Pending": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_SIGNOFF_PENDING_WITH_PARTNER_CLICK,
  "Signed-off with partner": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_SIGNED_OFF_WITH_PARTNER_CLICK,
  "On-Hold": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_ON_HOLD_CLICK,
  "Recon Finished by user": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_RECON_FINISHED_BY_USER_CLICK,
  "Balance Confirmed": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_BALANCE_CONFIRMED_CLICK,
};

export const ViewTickets = (props) => {
  const {
    companyId,
    branchCode,
    showSaveTicketModal,
    setShowSaveTicketModal,
    showDiscardTicketModal,
    setShowDiscardTicketModal,
    currentEditableBpId,
    storeAllCategories,
    currentSelectedTaskDetails,
  } = useContext(Recon360Context);
  const { actor } = useContext(userContext);

  const [ongoingTickets, setOngoingTickets] = React.useState<TicketDetails[]>(props.allTickets.ongoingTickets || []);
  const [previousTickets, setPreviousTickets] = React.useState<TicketDetails[]>(props.allTickets?.clearedTickets || []);
  const [discardedTickets, setDiscardedTickets] = React.useState<TicketDetails[]>(
    props.allTickets?.discardedTickets || []
  );
  const [tabValue, setTabValue] = React.useState(0);
  const [isFilterApplied, setIsFilterApplied] = React.useState(false);

  const [downloadingOngoingTasksReport, setDownloadingOngoingTasksReport] = React.useState(false);
  const [downloadingClearedTasksReport, setDownloadingClearedTasksReport] = React.useState(false);
  const [downloadingDiscardedTasksReports, setDownloadingDiscardedTasksReports] = React.useState(false);

  const editingBpId = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const currentTaskId = useRef(null);
  const [isStatusUpdating, setIsStatusUpdating] = useState(false);

  const [showBulkTasksModal, setShowBulkTasksModal] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [clearRows, setClearRows] = useState(false);

  const [showTaskAttachmentsModal, setShowTaskAttachmentsModal] = useState<boolean>(false);

  const [showEditTaskModal, setShowEditTaskModal] = useState<boolean>(false);
  const [selectedRowForEdit, setSelectedRowForEdit] = useState(null);

  useEffect(() => {
    let ongoingTicketsRaw = props.allTickets?.ongoingTickets;

    if (window.localStorage.getItem("bpIdsForWhichTasksAlreadyExists")) {
      const userData = JSON.parse(window.localStorage.getItem("bpIdsForWhichTasksAlreadyExists"));
      ongoingTicketsRaw = ongoingTicketsRaw.filter((ticket) => userData.includes(ticket.id));
      window.localStorage.removeItem("bpIdsForWhichTasksAlreadyExists");
    }

    setOngoingTickets(ongoingTicketsRaw);
    setPreviousTickets(props.allTickets?.clearedTickets);
    setDiscardedTickets(props.allTickets?.discardedTickets);
  }, [props.allTickets]);

  const downloadAccountTicketsReport = async (view: string) => {
    if (view === TicketViewType.Ongoing) setDownloadingOngoingTasksReport(true);
    else if (view === TicketViewType.Previous) setDownloadingClearedTasksReport(true);
    else if (view === TicketViewType.Discarded) setDownloadingDiscardedTasksReports(true);
    await useFetch(API_ENDPOINTS.GET_REPORT_FOR_ACCOUNT(view).url, "GET", {
      failureMessage: API_ENDPOINTS.GET_REPORT_FOR_ACCOUNT(view).failureMessage,
      thenCallBack: (response) => {
        const { excelBuffer, filename } = response.data;

        const excelData = Buffer.from(excelBuffer, "base64");
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const blob = new Blob([excelData], { type: fileType });

        saveAs(blob, filename);
        if (view === TicketViewType.Ongoing) setDownloadingOngoingTasksReport(false);
        else if (view === TicketViewType.Previous) setDownloadingClearedTasksReport(false);
        else if (view === TicketViewType.Discarded) setDownloadingDiscardedTasksReports(false);
        toast.success(<CustomToast message="Report downloaded successfully" />);
      },
      catchCallBack: () => {
        if (view === TicketViewType.Ongoing) setDownloadingOngoingTasksReport(false);
        else if (view === TicketViewType.Previous) setDownloadingClearedTasksReport(false);
        else if (view === TicketViewType.Discarded) setDownloadingDiscardedTasksReports(false);
        toast.error(<CustomToast message="Error in downloading report" />);
      },
    });
  };

  const updateTicketStatus = (status: string, ticketId: number) => {
    try {
      setIsStatusUpdating(true);
      uiLogger(mappingForUiLogStatuses[status].functionName, companyId.current, branchCode.current, {
        message: mappingForUiLogStatuses[status].message,
        businessPartnerId: editingBpId.current,
        ticketStatus: status,
        idOfTicket: ticketId,
      });
    } catch (error) {
      console.log(error);
    }

    useFetch(API_ENDPOINTS.UPDATE_TICKET_STATUS.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_TICKET_STATUS.failureMessage,
      data: {
        ticketId: ticketId,
        status: status,
      },
      thenCallBack: () => {
        props.fetchTickets();
        setIsStatusUpdating(false);
        setShowSaveTicketModal(false);
        setShowDiscardTicketModal(false);
        toast.success(<CustomToast message="Successfully changed ticket status" />);
      },
      catchCallBack: (error) => {
        const message = error?.response?.data?.message || "Error in changing ticket status";
        props.fetchTickets();
        setIsStatusUpdating(false);
        setShowSaveTicketModal(false);
        setShowDiscardTicketModal(false);
        toast.error(<CustomToast message={message} />);
      },
    });
  };

  // Updates Own and Bp Categories for each row on Blur
  const updateOwnAndBusinessPartnerMappingCategories = async (row, value: string[]) => {
    useFetch(API_ENDPOINTS.UPDATE_OWN_AND_BUSINESS_PARTNER_MAPPING_CATEGORIES.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_OWN_AND_BUSINESS_PARTNER_MAPPING_CATEGORIES.failureMessage,
      data: {
        ownId: actor.id,
        businessPartnerId: row.original.businessPartnerId,
        category: value,
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: () => {
        props.fetchTickets();
      },
    });
  };

  const renderGroupedOptions = () => {
    return Object.values(props.taskCategories).map((state) => {
      if (state === TicketStatuses.Saved || state === TicketStatuses.Discarded) return null;
      return [
        <ListSubheader
          disableSticky
          key={state as string}
          sx={{
            borderTop: "1px solid rgba(0,0,0,.12)",
            fontWeight: 700,
            fontSize: "16px",
            color: "rgba(0, 0, 0, 0.87)",
          }}
        >
          {state as string}
        </ListSubheader>,
        ...props.allStatuses
          .filter((ticket) => ticket.category === state)
          .map((item) => (
            <MenuItem
              key={item.status}
              value={item.status}
              sx={{
                color:
                  (item.status === TicketStatuses.IssueInLedger && "#EF6C00") ||
                  (item.status === TicketStatuses.CheckerRejected && "#EF6C00") ||
                  (item.status === TicketStatuses.ApproverRejected && "#EF6C00") ||
                  (item.status === TicketStatuses.TechnicalIssue && "#EF6C00"),
              }}
            >
              {item.status}
            </MenuItem>
          )),
      ];
    });
  };
  const columnDefinitionTickets = useMemo(
    (): ColDef<TicketDetails>[] => [
      {
        header: "Task ID",
        id: "id",
        sticky: "left",
        enablePinning: false,
        enableColumnDragging: false,
        enableResizing: false,
        accessorFn: (row) => row.id?.toString() || "",
        filterVariant: "autocomplete",
        Cell: ({ row }) => (
          <div style={{ textAlign: "center" }}>
            {row.original.metaStatus === TicketMetaStatuses.InProgress ? (
              <a
                onClick={() => {
                  const { businessPartnerId, businessPartner, id: ticketId, status } = row.original;
                  uiLogger(
                    uiLoggerNamesWorkflow.UI_WF_DB_PARTNER_SELECT_CLICK.functionName,
                    companyId.current,
                    branchCode.current,
                    {
                      message: uiLoggerNamesWorkflow.UI_WF_DB_PARTNER_SELECT_CLICK.message,
                      businessPartnerId: businessPartnerId,
                      idOfTicket: Number(ticketId),
                      ticketStatus: status,
                    }
                  );
                  const params = `bpId=${businessPartnerId}&bpName=${btoa(businessPartner)}`;
                  const uri = `/${actor.name}/recon360/Summary/Ledger?${params}`;
                  window.open(uri, "_blank")?.focus();
                }}
                className="textOverflow_hidden_anchor"
              >
                {row.original.id}
              </a>
            ) : (
              <span>{row.original.id}</span>
            )}
          </div>
        ),
        size: 135,
      },
      {
        header: "Business Partner",
        id: "Business partner name",
        sticky: "left",
        enablePinning: false,
        filterVariant: "autocomplete",
        accessorFn: (row) => row.businessPartner || "",
        Cell: ({ row }) => (
          <div
            style={{ width: "inherit" }}
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
          >
            {row.original.metaStatus === TicketMetaStatuses.InProgress ? (
              <a
                onClick={() => {
                  const { businessPartnerId, businessPartner, id: ticketId, status } = row.original;
                  uiLogger(
                    uiLoggerNamesWorkflow.UI_WF_DB_PARTNER_SELECT_CLICK.functionName,
                    companyId.current,
                    branchCode.current,
                    {
                      message: uiLoggerNamesWorkflow.UI_WF_DB_PARTNER_SELECT_CLICK.message,
                      businessPartnerId: businessPartnerId,
                      idOfTicket: Number(ticketId),
                      ticketStatus: status,
                    }
                  );
                  const params = `bpId=${businessPartnerId}&bpName=${btoa(businessPartner)}`;
                  const uri = `/${actor.name}/recon360/Summary/Ledger?${params}`;
                  window.open(uri, "_blank")?.focus();
                }}
                className="textOverflow_hidden_anchor"
              >
                {row.original.businessPartner}
              </a>
            ) : (
              <span>{row.original.businessPartner}</span>
            )}
          </div>
        ),
        size: 250,
        minSize: 250,
      },
      {
        header: "Vendor Code",
        accessorFn: (row) => row?.bpVendorCode || "",
        enableSorting: true,
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit" }}
          >
            {row.original.bpVendorCode ? row.original.bpVendorCode : ""}
          </div>
        ),
        size: 200,
        filterVariant: "autocomplete",
        filterFn: "contains",
      },
      {
        header: "Category",
        accessorFn: (row) => row.category?.join(", ") || "",
        size: 200,
        filterVariant: "multi-select",
        Cell: ({ row }) => (
          <div className="category_ui" style={{ width: "100%" }}>
            <Autocomplete
              limitTags={1}
              value={row.original.category !== null && row.original.category?.length > 0 ? row.original.category : []}
              onChange={(_ev, val) => {
                let value = val as string[];
                if (value !== null && value?.length > 0) {
                  if (value[value.length - 1]?.includes("+Add => ")) {
                    value[value.length - 1] = value[value.length - 1].replace('+Add => "', "");
                    value[value.length - 1] = value[value.length - 1].replace('"', "");
                    const removeEmptyVal = value?.filter((el) => {
                      return el !== "";
                    });
                    value = removeEmptyVal;
                  }
                }
                storeAllCategories.current = [...new Set([...storeAllCategories.current, ...value])];
                updateOwnAndBusinessPartnerMappingCategories(row, value);
              }}
              noOptionsText={"Enter a new category"}
              multiple={true}
              id="tags-outlined"
              options={storeAllCategories?.current}
              getOptionLabel={(option) => option}
              filterSelectedOptions={true}
              filterOptions={(_, params) => {
                const filtered = filter(
                  storeAllCategories?.current === null ? [] : storeAllCategories?.current,
                  params
                );
                // Suggest the creation of a new value
                if (params.inputValue !== "") {
                  filtered.push(`+Add => "${params.inputValue.trim()}"`);
                }
                return filtered;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add"
                  sx={{
                    "& input": {
                      px: "12px !important",
                    },
                  }}
                />
              )}
              sx={{
                "&, & div": {
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                },
                "& fieldset": {
                  borderWidth: "0.4px",
                  borderRadius: "12px",
                },
              }}
            />
          </div>
        ),
      },
      {
        header: "Status",
        id: "Status",
        filterVariant: "multi-select",
        accessorFn: (row) => row.status || "",
        Cell: ({ row, column }) => {
          const [anchorElDispute, setAnchorElDispute] = React.useState(null);
          const openDispute = Boolean(anchorElDispute);

          function handleClickDispute(event) {
            setAnchorElDispute(event.currentTarget);
          }
          function handleCloseDispute() {
            setAnchorElDispute(null);
          }
          const onChangeHandler = (e) => {
            const newValue = e.target.value;
            if (newValue !== BackendTicketStatuses.Saved || newValue !== BackendTicketStatuses.Discarded) {
              row.original.status = newValue;
              updateTicketStatus(newValue, row.original.id);
            }
          };
          return (
            <Select
              open={openDispute}
              onOpen={handleClickDispute}
              onClose={handleCloseDispute}
              disableUnderline={true}
              renderValue={() => row.original.status}
              displayEmpty={true}
              sx={{
                width: "inherit",
              }}
              disabled={
                column.getIsGrouped() ||
                row.original.metaStatus !== TicketMetaStatuses.InProgress ||
                selectedRow.length > 0
              }
              variant="standard"
              value={row.original.status}
              onChange={onChangeHandler}
              onFocus={() => {
                currentEditableBpId.current = row.original.businessPartnerId.toString();
                editingBpId.current = row.original.businessPartnerId;
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    "& .MuiMenu-list": {
                      paddingBottom: 0,
                      paddingTop: 0,
                      maxHeight: 450,
                    },
                  },
                },
              }}
            >
              {renderGroupedOptions()}

              <div
                style={{
                  position: "sticky",
                  bottom: "0",
                  backgroundColor: "#fff",
                  zIndex: 100,
                  display: "flex",
                  flexDirection: "column",
                  padding: "16px",
                  gap: "16px",
                  borderTop: "2px solid #e7e7e7",
                  marginTop: "8px",
                }}
              >
                <Button
                  variant="contained"
                  className="theme_btn fs_14 fw_500"
                  sx={{ borderRadius: "4px !important", padding: "7px 16px" }}
                  onClick={() => {
                    currentTaskId.current = row.original.id;
                    setAnchorElDispute(null);
                    setShowSaveTicketModal(true);
                  }}
                >
                  SAVE/SIGN OFF TASK
                </Button>
                <Button
                  className="fs_14 fw_500"
                  variant="outlined"
                  color="error"
                  sx={{ borderRadius: "4px !important", padding: "6px 16px" }}
                  onClick={() => {
                    currentTaskId.current = row.original.id;
                    setAnchorElDispute(null);
                    setShowDiscardTicketModal(true);
                  }}
                >
                  DISCARD TASK
                </Button>
              </div>
            </Select>
          );
        },
        size: 250,
        minSize: 250,
      },
      {
        header: "Task Label",
        id: "taskLabel",
        filterVariant: "multi-select",
        filterFn: (row, _, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.taskLabel);
        },
        accessorFn: (row) => row.taskLabel || "",
        Cell: ({ row }) => {
          return (
            <div
              className="textOverflow_hidden"
              ref={(th) => {
                if (th && isOverFlowing(th)) th.title = th.innerText;
              }}
              style={{ width: "inherit" }}
            >
              {row.original.taskLabel ? row.original.taskLabel : ""}
            </div>
          );
        },
        size: 180,
        minSize: 180,
      },

      {
        header: "Maker",
        id: "Maker",
        filterVariant: "multi-select",
        filterFn: (row, _, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.maker);
        },
        accessorFn: (row) => row.maker || "",
        Cell: ({ row }) => {
          return (
            <div
              className="textOverflow_hidden"
              ref={(th) => {
                if (th && isOverFlowing(th)) th.title = th.innerText;
              }}
              style={{ width: "inherit" }}
            >
              {row.original.maker ? row.original.maker : "-"}
            </div>
          );
        },
        size: 180,
        minSize: 180,
      },
      {
        header: "Checker",
        id: "Checker",
        filterVariant: "multi-select",
        filterFn: (row, _, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.checker);
        },
        accessorFn: (row) => row.checker || "",
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit" }}
          >
            {row.original.checker ? row.original.checker : "-"}
          </div>
        ),
        size: 180,
        minSize: 180,
      },
      {
        header: "Approver",
        id: "Approver",
        filterVariant: "multi-select",
        filterFn: (row, _, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.approver ? row.original.approver : "-");
        },
        accessorFn: (row) => row.approver || "-",
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit" }}
          >
            {row.original.approver ? row.original.approver : "-"}
          </div>
        ),
        size: 190,
        minSize: 190,
      },
      {
        header: "Last User",
        id: "Last User",
        filterVariant: "multi-select",
        accessorFn: (row) => row.lastActiveUser || "-",
        filterFn: (row, _, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.lastActiveUser ? row.original.lastActiveUser : "-");
        },
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit" }}
          >
            {row.original.lastActiveUser ? row.original.lastActiveUser : "-"}
          </div>
        ),
        size: 190,
        minSize: 190,
      },
      {
        header: "Priority",
        id: "Priority",
        filterVariant: "multi-select",
        accessorFn: (row) => row.priority || "",
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">{row.original.priority ? row.original.priority : "-"}</div>
        ),
        size: 180,
        minSize: 180,
      },

      {
        header: "Bucket",
        id: "Bucket",
        accessorFn: (row) => row.metaStatus || "",
        filterVariant: "multi-select",
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">{row.original.metaStatus ? row.original.metaStatus : "-"}</div>
        ),
        size: 180,
        minSize: 180,
      },
      {
        header: "Due Date",
        id: "Due Date",
        filterVariant: "date-range",
        filterFn: (row, _, filterValue) => {
          const startDate = filterValue[0] && filterValue[0].$d;
          const endDate = filterValue[1] && filterValue[1].$d;
          return filterDateBetweenStartAndEndDate(startDate, endDate, row.original.dueDate);
        },
        accessorFn: (row) => new Date(row.dueDate),
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">{row.original.dueDate ? formatDate(row.original.dueDate) : "-"}</div>
        ),
        size: 350,
        minSize: 350,
      },
      {
        header: "Creation Date",
        id: "Creation Date",
        filterVariant: "date-range",
        filterFn: (row, _, filterValue) => {
          const startDate = filterValue[0] && filterValue[0].$d;
          const endDate = filterValue[1] && filterValue[1].$d;
          return filterDateBetweenStartAndEndDate(startDate, endDate, row.original.createdAt);
        },
        accessorFn: (row) => new Date(row.createdAt),
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">{row.original.createdAt ? formatDate(row.original.createdAt) : "-"}</div>
        ),
        size: 350,
        minSize: 350,
      },
      {
        header: "Time of Last Status Change",
        id: "Time of Last Status Change",
        filterVariant: "date-range",
        filterFn: (row, _, filterValue) => {
          const startDate = filterValue[0] && filterValue[0].$d;
          const endDate = filterValue[1] && filterValue[1].$d;
          return filterDateBetweenStartAndEndDate(startDate, endDate, row.original.lastStatusChangeTime);
        },
        accessorFn: (row) => new Date(row.lastStatusChangeTime),
        // Render lastStatusChangeTime in the format "YYYY-MM-DD  HH:MM AM/PM"
        // AM/PM is compulsory
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.lastStatusChangeTime ? formatDateTime24Hours(row.original.lastStatusChangeTime) : "-"}
          </div>
        ),
        size: 350,
        minSize: 350,
      },
      {
        header: "Remarks",
        id: "remarks",
        accessorFn: (row) => row.remarks || "",
        filterFn: "contains",
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit" }}
          >
            {row.original.remarks ? row.original.remarks : "-"}
          </div>
        ),
        size: 250,
        minSize: 250,
      },
      {
        header: "Mail Follow Ups",
        id: "mailfollowups",
        filterVariant: "range",
        filterFn: "betweenInclusive",
        accessorFn: (row) => row.followUpCounts?.[TicketFollowUpType?.Mail] || 0,
        Cell: ({ row }) => (
          <Stack direction="row" columnGap={1} justifyContent="center">
            <span className="fs_16 fw_600">
              {(row.original.followUpCounts?.[TicketFollowUpType?.Mail] || "0") + " Times"}
            </span>
            <span>
              {row.original.followUpCounts?.[TicketFollowUpType?.Mail] !== 0 &&
                formatDate(row.original.followUpLastTime?.[TicketFollowUpType?.Mail])}
            </span>
          </Stack>
        ),
        size: 220,
        minSize: 220,
      },
      {
        header: "Conversation Follow Ups",
        id: "conversationfollowups",
        filterVariant: "range",
        filterFn: "betweenInclusive",
        accessorFn: (row) => row.followUpCounts?.[TicketFollowUpType?.Conversation] || 0,
        Cell: ({ row }) => (
          <Stack direction="row" columnGap={1} justifyContent="center">
            <span className="fs_16 fw_600">
              {(row.original.followUpCounts?.[TicketFollowUpType?.Conversation] || "0") + " Times"}
            </span>
            <span>
              {row.original.followUpCounts?.[TicketFollowUpType?.Conversation] !== 0 &&
                formatDate(row.original.followUpLastTime?.[TicketFollowUpType?.Conversation])}
            </span>
          </Stack>
        ),
        size: 300,
        minSize: 300,
      },
    ],

    [props.allStatuses, props.allUsersWithIds, ongoingTickets, previousTickets, discardedTickets, selectedRow]
  );

  return (
    <div className="view-tickets">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={(e, val) => {
            e.preventDefault();
            setTabValue(val);
          }}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="ONGOING TASKS" sx={{ textTransform: "none" }} />
          <Tab label="PREVIOUS TASKS" sx={{ textTransform: "none" }} />
          <Tab label="DISCARDED TASKS" sx={{ textTransform: "none" }} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <ViewTicketsFilter
          rowsDataTicket={ongoingTickets}
          setRowsDataTicket={setOngoingTickets}
          storeRowsDataTicket={props.allTickets?.ongoingTickets || []}
          openFilter={isFilterApplied}
          setOpenFilter={setIsFilterApplied}
          allStatuses={props.allStatuses.map((status) => status.status) || []}
          allUsers={props.allUsers || []}
          allPartners={ongoingTickets.map((ticket) => ticket.businessPartner)}
        />
        <Box sx={{ backgroundColor: "#fff", borderRadius: "20px", boxShadow: 5 }}>
          <Stack direction="row" p={2}>
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              Ongoing Tasks
            </Typography>
            <Button
              onClick={() => setShowBulkTasksModal(true)}
              variant="outlined"
              sx={{
                fontSize: "15px",
                fontWeight: "500",
                ml: "auto",
                padding: "8px 22px",
                borderRadius: "4px !important",
                color: "rgba(84, 28, 76, 1)",
                borderColor: "rgba(84, 28, 76, 0.50)",
                "&:hover, &.Mui-focusVisible": { borderColor: "rgba(84, 28, 76, 1)" },
              }}
            >
              BULK TASKS
            </Button>
            <NdButton
              variant="contained"
              sx={{ ml: 2 }}
              onClick={() => {
                uiLogger(
                  uiLoggerNamesWorkflow.UI_WF_DB_DOWNLOAD_TASK_REPORT_CLICK.functionName,
                  companyId.current,
                  branchCode.current,
                  { message: uiLoggerNamesWorkflow.UI_WF_DB_DOWNLOAD_TASK_REPORT_CLICK.message }
                );
                downloadAccountTicketsReport(TicketViewType.Ongoing);
              }}
              disabled={downloadingOngoingTasksReport}
            >
              <LoadingIcon loading={downloadingOngoingTasksReport} />
              Download Report
            </NdButton>
            <NdButton
              variant="contained"
              sx={{ ml: 2 }}
              onClick={() => {
                uiLogger(
                  uiLoggerNamesWorkflow.UI_WF_DB_FILTER_CLICK.functionName,
                  companyId.current,
                  branchCode.current,
                  {
                    message: uiLoggerNamesWorkflow.UI_WF_DB_FILTER_CLICK.message,
                  }
                );
                setIsFilterApplied(true);
              }}
            >
              Filter
            </NdButton>
          </Stack>
          <ReactTable
            enableFacetedValues={true}
            enableRowVirtualization={true}
            columns={columnDefinitionTickets}
            rows={ongoingTickets}
            enableAggregations={true}
            setSelectedRow={setSelectedRow}
            clearRowsSelection={clearRows}
            setClearRowsSelection={setClearRows}
            initialStateColumnVisibility={{ "Last User": false }}
            renderRowActionMenuItems={({ row, closeMenu }) => [
              <MenuItem
                key="editTask"
                disabled={row.original.metaStatus === TicketMetaStatuses.Saved}
                onClick={() => {
                  uiLogger(
                    uiLoggerNamesWorkflow.UI_WF_DB_ACTIONS_EDIT_CLICK.functionName,
                    companyId.current,
                    branchCode.current,
                    {
                      message: uiLoggerNamesWorkflow.UI_WF_DB_ACTIONS_EDIT_CLICK.message,
                      idOfTicket: row.original.id,
                    }
                  );
                  setShowEditTaskModal(true);
                  setSelectedRowForEdit(row.original);
                  closeMenu();
                }}
              >
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                Edit Task
              </MenuItem>,
              <MenuItem
                key="viewAttachments"
                onClick={() => {
                  currentSelectedTaskDetails.current = {
                    businessPartnerName: row.original.businessPartner,
                    taskId: row.original.id,
                  };
                  setShowTaskAttachmentsModal(true);
                  closeMenu();
                }}
              >
                <ListItemIcon>
                  <AttachFileIcon />
                </ListItemIcon>
                View Attachments
              </MenuItem>,
              <MenuItem
                key={"viewHistory"}
                onClick={() => {
                  currentSelectedTaskDetails.current = {
                    businessPartnerName: row.original.businessPartner,
                    taskId: row.original.id,
                  };
                  props.setShowTasksHistoryModal(true);
                  closeMenu();
                }}
              >
                <ListItemIcon>
                  <HistoryIcon />
                </ListItemIcon>
                View History
              </MenuItem>,
            ]}
            isSaving={isEditing}
            internalActionsOrder={["globalFilterToggle", "filterToggle", "showHideColumns", "fullScreenToggle"]}
          />
        </Box>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <ViewTicketsFilter
          rowsDataTicket={previousTickets}
          setRowsDataTicket={setPreviousTickets}
          storeRowsDataTicket={props.allTickets?.clearedTickets || []}
          openFilter={isFilterApplied}
          setOpenFilter={setIsFilterApplied}
          allStatuses={props.allStatuses.map((status) => status.status) || []}
          allUsers={props.allUsers || []}
          allPartners={previousTickets.map((ticket) => ticket.businessPartner)}
        />
        <Box sx={{ backgroundColor: "#fff", borderRadius: "20px", boxShadow: 5 }}>
          <Stack direction="row" p={2}>
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              Previous Tasks
            </Typography>
            <NdButton
              variant="contained"
              sx={{ ml: "auto" }}
              onClick={() => {
                uiLogger(
                  uiLoggerNamesWorkflow.UI_WF_DB_DOWNLOAD_TASK_REPORT_CLICK.functionName,
                  companyId.current,
                  branchCode.current,
                  {
                    message: uiLoggerNamesWorkflow.UI_WF_DB_DOWNLOAD_TASK_REPORT_CLICK.message,
                  }
                );
                downloadAccountTicketsReport(TicketViewType.Previous);
              }}
              disabled={downloadingClearedTasksReport}
            >
              <LoadingIcon loading={downloadingClearedTasksReport} />
              Download Report
            </NdButton>
            <NdButton
              variant="contained"
              sx={{ ml: 2 }}
              onClick={() => {
                uiLogger(
                  uiLoggerNamesWorkflow.UI_WF_DB_FILTER_CLICK.functionName,
                  companyId.current,
                  branchCode.current,
                  {
                    message: uiLoggerNamesWorkflow.UI_WF_DB_FILTER_CLICK.functionName,
                  }
                );
                setIsFilterApplied(true);
              }}
            >
              Filter
            </NdButton>
          </Stack>

          <ReactTable
            columns={columnDefinitionTickets}
            rows={previousTickets}
            enableRowSelection={false}
            enableAggregations={true}
            enableRowVirtualization={true}
            enableFacetedValues={true}
            internalActionsOrder={["globalFilterToggle", "filterToggle", "showHideColumns", "fullScreenToggle"]}
            initialStateColumnVisibility={{ "Last User": false }}
            renderRowActionMenuItems={({ row, closeMenu }) => [
              <MenuItem
                key="viewAttachments"
                onClick={() => {
                  currentSelectedTaskDetails.current = {
                    businessPartnerName: row.original.businessPartner,
                    taskId: row.original.id,
                  };
                  setShowTaskAttachmentsModal(true);
                  closeMenu();
                }}
              >
                <ListItemIcon>
                  <AttachFileIcon />
                </ListItemIcon>
                View Attachments
              </MenuItem>,
            ]}
          />
        </Box>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <ViewTicketsFilter
          rowsDataTicket={discardedTickets}
          setRowsDataTicket={setDiscardedTickets}
          storeRowsDataTicket={props.allTickets?.discardedTickets || []}
          openFilter={isFilterApplied}
          setOpenFilter={setIsFilterApplied}
          allStatuses={props.allStatuses.map((status) => status.status) || []}
          allUsers={props.allUsers || []}
          allPartners={discardedTickets.map((ticket) => ticket.businessPartner)}
        />
        <Box sx={{ backgroundColor: "#fff", borderRadius: "20px", boxShadow: 5 }}>
          <Stack direction="row" p={2}>
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              Discarded Tasks
            </Typography>
            <NdButton
              variant="contained"
              sx={{ ml: "auto" }}
              onClick={() => {
                uiLogger(
                  uiLoggerNamesWorkflow.UI_WF_DB_DOWNLOAD_TASK_REPORT_CLICK.functionName,
                  companyId.current,
                  branchCode.current,
                  { message: uiLoggerNamesWorkflow.UI_WF_DB_DOWNLOAD_TASK_REPORT_CLICK.message }
                );
                downloadAccountTicketsReport(TicketViewType.Discarded);
              }}
              disabled={downloadingDiscardedTasksReports}
            >
              <LoadingIcon loading={downloadingDiscardedTasksReports} />
              Download Report
            </NdButton>
            <NdButton
              variant="contained"
              sx={{ ml: 2 }}
              onClick={() => {
                uiLogger(
                  uiLoggerNamesWorkflow.UI_WF_DB_FILTER_CLICK.functionName,
                  companyId.current,
                  branchCode.current,
                  { message: uiLoggerNamesWorkflow.UI_WF_DB_FILTER_CLICK.message }
                );
                setIsFilterApplied(true);
              }}
            >
              Filter
            </NdButton>
          </Stack>

          <ReactTable
            columns={columnDefinitionTickets}
            rows={discardedTickets}
            enableRowSelection={false}
            enableAggregations={true}
            enableRowVirtualization={true}
            internalActionsOrder={["globalFilterToggle", "filterToggle", "showHideColumns", "fullScreenToggle"]}
            enableFacetedValues={true}
            initialStateColumnVisibility={{ "Last User": false }}
          />
        </Box>
      </TabPanel>
      {showSaveTicketModal && (
        <SaveTicketModal
          showSaveTicketModal={showSaveTicketModal}
          setShowSaveTicketModal={setShowSaveTicketModal}
          changeStatus={updateTicketStatus}
          ticketId={currentTaskId.current}
          isStatusUpdating={isStatusUpdating}
        />
      )}
      {showDiscardTicketModal && (
        <DiscardTicketModal
          showDiscardTicketModal={showDiscardTicketModal}
          setShowDiscardTicketModal={setShowDiscardTicketModal}
          changeStatus={updateTicketStatus}
          ticketId={currentTaskId.current}
          isStatusUpdating={isStatusUpdating}
        />
      )}
      <BulkTasksModal
        showBulkTasksModal={showBulkTasksModal}
        setShowBulkTasksModal={setShowBulkTasksModal}
        selectedTasks={selectedRow}
        allUsers={props.allUsersWithIds}
        allStatuses={props.allStatuses.map((status) => status.status)}
        fetchTickets={props.fetchTickets}
        setClearRows={setClearRows}
      />
      {showTaskAttachmentsModal && (
        <TaskAttachmentsModal
          open={showTaskAttachmentsModal}
          setOpen={setShowTaskAttachmentsModal}
          businessPartnerName={currentSelectedTaskDetails.current.businessPartnerName}
          ticketId={currentSelectedTaskDetails.current.taskId}
          companyId={companyId.current}
          branchCode={branchCode.current}
          serviceType={NAKAD_SERVICE.Recon}
        />
      )}
      {showEditTaskModal && (
        <EditTaskModal
          type="workflow"
          open={showEditTaskModal}
          setOpen={setShowEditTaskModal}
          allUsers={props.allUsersWithIds}
          row={selectedRowForEdit}
          fetchRowsData={props.fetchTickets}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          companyId={companyId.current}
          branchCode={branchCode.current}
          allTaskLabels={props.allTaskLabels}
          getAllTaskLabels={props.getAllTaskLabels}
        />
      )}
    </div>
  );
};
